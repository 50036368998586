import { post } from "./apiManager";

export const sendNotification = (body, onSuccess, onFailed, onComplete) => {
  post(
    "Notification",
    body,
    "sendNotification",
    onSuccess,
    onFailed,
    onComplete
  );
};

export const sendNotificationV2 = (data, onSuccess, onFailed, onComplete) => {
  fetch(`${process.env.REACT_APP_NEW_API_BASE_URL}notification`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({ data }),
  })
    .then((res) => res.json())
    .then((response) => {
      onSuccess(response);
    })
    .catch((error) => {
      onFailed(error);
    });
};

export const sendNotificationV3 = async (Id) => {
  // console.log(" ID: " + Id);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_NEW_API_BASE_URL}sms/send-appointment-notification`,
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
        },
        body: JSON.stringify({
          AppointmentId: 424071,
          NotificationType: "Appintment Placed",
        }),
      }
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    console.log("Returning data: ", data);
    return data;
  } catch (error) {
    console.error("Error fetching data: ", error);
    // throw error;
  }
};
