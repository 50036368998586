import { Box, Divider, Grid, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";

import { Close, FiberManualRecord } from "@mui/icons-material";
import GavelIcon from "@mui/icons-material/Gavel";
import InfoIcon from "@mui/icons-material/Info";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import { styled, useTheme } from "@mui/material/styles";
import { FAQ, TERMS_AND_CONDITIONS, WHAT_WE_OFFER } from "../../data";
import "./index.css";
import MenuItem from "./MenuItem";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import medicaLogo from "../../assets/medica.png";
import logo from "../../assets/logo.png";

const StyledBox = styled(Box)`
  ${({ theme }) => `
  transition: ${theme.transitions.create(["all", "transform"], {
    duration: theme.transitions.duration.complex,
  })};
  `}
`;

const PageMenu = ({ isOpen, setOpen, showing, setShowing }) => {
  const [contentVisible, setContentVisible] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setContentVisible(true);
      }, 500);
    } else {
      setContentVisible(false);
    }
  }, [isOpen]);

  return (
    <StyledBox
      sx={{
        position: "absolute",
        right: isOpen ? 0 : 30,
        top: isOpen ? 0 : 30,
        backgroundColor: "white",
        width: isOpen ? "100%" : "0%",
        overflowY: "auto",
        height: isOpen ? "100%" : "0%",
        borderRadius: 3,
        zIndex: 5,
      }}
    >
      <Grid container sx={{ height: "100%" }} p={2} pr={0}>
        <Grid
          item
          xs={4}
          sx={{
            backgroundColor: "#EFEFEF",
            borderRadius: 1,
            display: { xs: "none", md: "unset" },
            position: "relative",
          }}
          pt={1.2}
        >
          {/* desktop view */}
          {contentVisible && (
            <>
              <MenuItem
                title={"Frequently Asked Questions"}
                icon={<FiberManualRecord sx={{ fontSize: 10 }} />}
                selected={showing === 0}
                onClick={() => {
                  setShowing(0);
                }}
              />
              <MenuItem
                title={"Terms and Conditions"}
                icon={<FiberManualRecord sx={{ fontSize: 10 }} />}
                selected={showing === 1}
                onClick={() => {
                  setShowing(1);
                }}
              />
              <MenuItem
                title={"What we offer"}
                icon={<FiberManualRecord sx={{ fontSize: 10 }} />}
                selected={showing === 2}
                onClick={() => {
                  setShowing(2);
                }}
              />
              <MenuItem
                title={"About"}
                icon={<FiberManualRecord sx={{ fontSize: 10 }} />}
                selected={showing === 3}
                onClick={() => {
                  setShowing(3);
                }}
              />
            </>
          )}

          <Box
            position="absolute"
            bottom={20}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            width={"100%"}
            px={1}
            gap={0.5}
          >
            <img src={logo} alt="docnote logo" width={"100px"} />
            <Divider
              orientation="vertical"
              sx={{
                mt: 0.5,
                width: "2px",
                height: "25px",
                backgroundColor: "#ABABAB",
              }}
            />
            <img
              src={medicaLogo}
              alt="medica logo"
              width={"120px"}
              style={{
                marginTop: "5px",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sx={{ display: { xs: "unset", sm: "none" } }}>
          <Box display={"flex"} justifyContent="end" pr={1.5}>
            <span
              onClick={() => {
                setOpen(false);
              }}
            >
              <Close />
            </span>
          </Box>
          {contentVisible && (
            <Box
              display={"flex"}
              width={"100%"}
              justifyContent="space-between"
              px={3}
            >
              <MenuItem
                title={"FAQ"}
                icon={<LiveHelpIcon />}
                selected={showing === 0}
                p={0}
                gap={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setShowing(0);
                }}
              />
              <MenuItem
                title={"T & C"}
                icon={<GavelIcon />}
                selected={showing === 1}
                p={0}
                gap={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setShowing(1);
                }}
              />

              <MenuItem
                title={"We Offer"}
                p={0}
                gap={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                icon={<LocalOfferIcon />}
                selected={showing === 2}
                onClick={() => {
                  setShowing(2);
                }}
              />
              <MenuItem
                title={"About"}
                icon={<InfoIcon />}
                selected={showing === 3}
                p={0}
                gap={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
                onClick={() => {
                  setShowing(3);
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid item xs>
          {contentVisible && (
            <>
              {/* --------------------- 0th content ------------------------- */}
              {showing === 0 && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    mr={2}
                    pl={2}
                  >
                    <Typography
                      fontWeight={"bold"}
                      sx={{
                        fontSize: { xs: "1.3rem", md: "1.5rem" },
                      }}
                    >
                      Frequently Asked Questions
                    </Typography>
                    {isDesktop && (
                      <span
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Close />
                      </span>
                    )}
                  </Box>
                  <Box
                    pl={2}
                    pr={1}
                    mr={2}
                    sx={{
                      height: { xs: "45rem", sm: "29rem" },

                      overflowY: "auto",
                      scrollbarWidth: "thin",
                    }}
                    textAlign="justify"
                  >
                    {FAQ.map((oneEl, index) => {
                      return (
                        <div key={index}>
                          <Typography
                            fontWeight={"bold"}
                            sx={{ pt: 2, pb: 0.5 }}
                            fontSize="1rem"
                          >
                            • {oneEl.question}
                          </Typography>
                          <Typography
                            sx={{
                              ml: 2,
                            }}
                          >
                            {oneEl.answer}
                          </Typography>
                        </div>
                      );
                    })}

                    <div>
                      <Typography
                        fontWeight={"bold"}
                        sx={{ pt: 2, pb: 0.5 }}
                        fontSize="1rem"
                      >
                        Troubleshooting microphone issues
                      </Typography>
                      <Typography>
                        If you are experiencing issues using your microphone,
                        follow these steps to troubleshoot the issue:
                      </Typography>
                      <div>
                        <ul>
                          <li>
                            Ensure the microphone is not on mute. If you see the
                            muted Audio icon in the meeting controls, tap it to
                            unmute yourself:
                            <ul>
                              <li>
                                If you are still muted, the doctor may have
                                muted you upon entering the consultation. The
                                doctor will allow you to speak when ready
                              </li>
                              <li>
                                Ensure you have connected your mobile device's
                                audio. If you see the following Join Audio icon
                                in the meeting controls, tap it and select Call
                                Over the Internet.
                              </li>
                            </ul>
                          </li>
                          <li>
                            Try using earphones with a microphone. Ensure that
                            browser has access to your device's microphone.
                            <ul>
                              <li>
                                iOS:
                                <ul>
                                  <li>Go to Settings</li>
                                  <li>Tap Privacy </li>
                                  <li>
                                    Switch on the toggle for the browser next to
                                    the Microphone
                                  </li>
                                </ul>
                              </li>
                              <li>
                                Android:
                                <ul>
                                  <li>Go to Settings </li>
                                  <li>Tap Apps & notifications </li>
                                  <li>
                                    Tap App permissions or Permission Manager
                                  </li>
                                  <li>
                                    Switch on the toggle for the browser next to
                                    the Microphone
                                  </li>
                                  <li>
                                    Note: Android settings vary between
                                    manufacturers and service providers, so
                                    these instructions may not exactly match
                                    your device
                                  </li>
                                  <li>
                                    Ensure that no other applications are using
                                    the microphone at the same time
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Box>
                </>
              )}
              {/* --------------------- 0th content ------------------------- */}

              {/* --------------------- 1st content ------------------------- */}
              {showing === 1 && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    mr={1}
                    pl={2}
                  >
                    <Typography fontWeight={"bold"} fontSize="1.5rem">
                      Terms and Conditions
                    </Typography>
                    {isDesktop && (
                      <span
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Close />
                      </span>
                    )}
                  </Box>
                  <Box
                    pl={2}
                    pr={1}
                    mr={2}
                    sx={{
                      height: { xs: "45rem", sm: "29rem" },
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                    }}
                    textAlign="justify"
                  >
                    {TERMS_AND_CONDITIONS.map((terms, index) => {
                      return (
                        <>
                          <div key={index}>
                            <Typography
                              fontWeight={"bold"}
                              sx={{ pt: 2, pb: 0.5 }}
                              fontSize="1rem"
                            >
                              {terms.title}
                            </Typography>
                            <Typography>{terms.content}</Typography>
                            {terms.pointCotent.map((onePoint, index) => {
                              return (
                                <>
                                  {onePoint.title !== "" && (
                                    <Typography sx={{ pt: 2, pb: 0.5 }}>
                                      {onePoint.title}
                                    </Typography>
                                  )}
                                  <ul>
                                    {onePoint.points.map((oneEl, index) => {
                                      if (Array.isArray(oneEl)) {
                                        return (
                                          <>
                                            <ol
                                              style={{
                                                marginLeft: "25px",
                                                marginTop: "5px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              {oneEl.map((oneEl, index) => {
                                                return <li>{oneEl}</li>;
                                              })}
                                            </ol>
                                          </>
                                        );
                                      }
                                      return <li>{oneEl}</li>;
                                    })}
                                  </ul>
                                </>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                  </Box>
                </>
              )}
              {/* --------------------- 1st content ------------------------- */}

              {/* --------------------- 2nd content ------------------------- */}
              {showing === 2 && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    mr={2}
                    pl={2}
                  >
                    <Typography fontWeight={"bold"} fontSize="1.5rem">
                      What we offer
                    </Typography>
                    {isDesktop && (
                      <span
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Close />
                      </span>
                    )}
                  </Box>
                  <Box
                    pl={2}
                    pr={5.5}
                    mr={2}
                    sx={{
                      height: { xs: "45rem", sm: "29rem" },
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                    }}
                    textAlign="justify"
                  >
                    {/* {WHAT_WE_OFFER.map((oneEl, index) => {
                      return (
                        <div key={index}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            {oneEl.icon}
                            <Typography
                              fontWeight={"bold"}
                              sx={{ mt: 1.25, pb: 0.5, ml: 0.8 }}
                              fontSize="1rem"
                            >
                              {oneEl.title}
                            </Typography>
                          </Box>
                          <Typography sx={{}}>{oneEl.content}</Typography>
                          <Divider
                            sx={{
                              mt: 2,
                              mb: 1,
                            }}
                          />
                        </div>
                      );
                    })} */}
                  </Box>
                </>
              )}
              {/* --------------------- 2nd content ------------------------- */}

              {/* --------------------- 3rd content ------------------------- */}
              {showing === 3 && (
                <>
                  <Box
                    display={"flex"}
                    justifyContent="space-between"
                    mr={2}
                    pl={2}
                  >
                    <Typography fontWeight={"bold"} fontSize="1.5rem">
                      About
                    </Typography>
                    {isDesktop && (
                      <span
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        <Close />
                      </span>
                    )}
                  </Box>
                  <Box
                    pl={2}
                    pr={5.5}
                    mr={2}
                    sx={{
                      height: { xs: "45rem", sm: "29rem" },
                      overflowY: "auto",
                      scrollbarWidth: "thin",
                    }}
                    textAlign="justify"
                  >
                    <Typography
                      sx={{
                        mt: 1,
                      }}
                    >
                      MEDICA - Hosted Clinical Record management and online
                      doctor consultation platform for healthcare services
                      providers in partnership with DocNote.
                    </Typography>
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                      width={"100%"}
                      mt={2}
                      gap={0.5}
                    >
                      <img src={logo} alt="docnote logo" width={"100px"} />
                      <Divider
                        orientation="vertical"
                        sx={{
                          mt: 0.5,
                          width: "2px",
                          height: "25px",
                          backgroundColor: "#ABABAB",
                        }}
                      />
                      <img
                        src={medicaLogo}
                        alt="medica logo"
                        width={"120px"}
                        style={{
                          marginTop: "5px",
                        }}
                      />
                    </Box>
                  </Box>
                </>
              )}
              {/* --------------------- 3rd content ------------------------- */}
            </>
          )}
        </Grid>
      </Grid>
    </StyledBox>
  );
};

export default PageMenu;
