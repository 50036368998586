import { Box, Chip, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { appointmentStatus } from "../../app/apiManager/channelingStatusService";
import {
  getDoctor,
  getDoctorSpecialization,
} from "../../app/apiManager/doctorServices";
import { getByBranch, getPatientById } from "../../app/apiManager/patientServices";
import ContentCard from "../../components/ContentCard/ContentCard";
import DoctorSummery from "../../components/DoctorSummery/DoctorSummery";
import FlexBox from "../../components/FlexBox/FlexBox";
import TitleAndContent from "../../components/TitleAndContent/TitleAndContent";
import { APPOINTMENT_STATUS, PAYMENT_METHODS } from "../../const";
import { clearAll } from "../../reducers/appointmentInfoSlice";
import {
  setAppointment
} from "../../reducers/appointmentStatusSlice";
import { convertToCurrency } from "../../utils/convert-to-currency";

const StepOne = ({
  setActiveStep,
  appointment,
  doctor,
  setDoctor,
  setAppointmentCompleted,
  setAppointmentCancelled,
}) => {

  const [patient, setPatient] = useState({});

  const [queryParams, setQueryParams] = useSearchParams();
  const [doctorSpecialization, setDoctorSpecialization] = useState("");
  const [requestAttempt, setRequestAttempt] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(-1);
  const [channelingStatus, setChannelingStatus] = useState({
    status: "Apointment Confirmed",
    color: "darkgreen",
  });
  const dispatch = useDispatch();

  const fetchBranchData = async (doctorId, userId) => {
    try {
      const res = await getByBranch(doctorId, userId);
      setDoctor(res.Data);
      console.log("Branch Response: ", res);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const appointmentId = queryParams.get("appointmentId");
    dispatch(clearAll());
    let timeout = null;
    let timeoutOnStatus = null;

    if (requestAttempt < 3) {
      timeoutOnStatus = appointmentStatus(appointmentId, (res) => {
        if (res) {
          setRequestAttempt(3);
        } else {
          setRequestAttempt(requestAttempt + 1);
        }
        const appointment = res[0];
        console.log(res);

        fetchBranchData(appointment.DoctorId, appointment.UserId);

        getPatientById(
          appointment.PatientId,
          (res) => {
            if (res.Status === 1000) {
              if (res.Data[0]) {
                let fetchedPatient = res.Data[0];
                let name =
                  fetchedPatient.Title + " " +
                  fetchedPatient.FirstName + " " +
                  fetchedPatient.LastName;
                let email = fetchedPatient.Email;
                let phoneNo = fetchedPatient.Mobile;

                setPatient({
                  name,
                  email,
                  phoneNo,
                });
              } else {
                setPatient({});
              }
            }
          },
          (err) => {
            console.error(err);
          }
        );

        getDoctorSpecialization(appointment.DoctorId, (res) => {
          setDoctorSpecialization(res[0].SpecializationName);
        });

        dispatch(setAppointment({ appointment: appointment }));

        switch (appointment.ChanalingStatus) {
          case APPOINTMENT_STATUS.CANCELLED_AS_NO_SHOW: {
            setChannelingStatus({
              status: "Appointment Cancelled as No Show",
              color: "red",
            });
            setAppointmentCompleted(true);
            setAppointmentCancelled(true);
            return setTimeout(() => {
              setActiveStep(4);
            }, 2000);
          }
          case APPOINTMENT_STATUS.COMPLETED: {
            setChannelingStatus({
              status: "Appointment Completed",
              color: "darkgreen",
            });
            setAppointmentCancelled(false);
            setAppointmentCompleted(true);
            return setTimeout(() => {
              setActiveStep(4);
            }, 2000);
          }
          case APPOINTMENT_STATUS.CANCELLED_WITH_RESCHEDULED: {
            setChannelingStatus({
              status: "Appointment Cancelled and Rescheduled",
              color: "red",
            });
            setAppointmentCompleted(true);
            setAppointmentCancelled(true);
            return setTimeout(() => {
              setActiveStep(4);
            }, 2000);
          }
          case APPOINTMENT_STATUS.CANCELLED_WITH_REFUND: {
            setChannelingStatus({
              status: "Appointment Cancelled and Refunded",
              color: "red",
            });
            return setTimeout(() => {
              setAppointmentCompleted(true);
              setAppointmentCancelled(true);
              setActiveStep(4);
            }, 2000);
          }
          default: {
            setChannelingStatus({
              status: "Apointment Confirmed",
              color: "darkgreen",
            });
          }
        }

        setPaymentMethod(appointment.UserSaved);
      });
    }
    return () => {
      clearTimeout(timeout);
      clearTimeout(timeoutOnStatus);
    };
  }, [requestAttempt]);

  let paymentChipText = "Loading...";

  switch (paymentMethod) {
    case PAYMENT_METHODS.PAY_NOW:
      paymentChipText = "Credit/Debit Card";
      break;

    case PAYMENT_METHODS.PROMO:
      paymentChipText = "Promo";
      break;

    case PAYMENT_METHODS.CASH_PAYMENT:
      paymentChipText = "Cash Payment";
      break;

    default:
      paymentChipText = null;
      break;
  }

  const channeilingFees = doctor.ZoomEmail ? JSON.parse(doctor.ZoomEmail) : {};

  console.log(channeilingFees);

  return (
    <>
      <Box pb={1.2}>
        <Typography
          fontWeight={"bold"}
          fontSize={"1.5rem"}
          sx={{
            color: channelingStatus.color,
          }}
        >
          {channelingStatus.status}
        </Typography>
      </Box>

      <Grid container columnSpacing={1.5} rowSpacing={1}>
        <Grid item xs={12} sm={7}>
          <ContentCard
            sx={{
              pl: { sm: 2 },
              pb: 3,
              boxShadow: 0.5,
              border: "1px solid #ececec",
            }}
          >
            <DoctorSummery
              doctorImage={
                doctor.doctorId
                  ? `${process.env.REACT_APP_VC_DOMAIN}${process.env.REACT_APP_IMAGE_PATH}${doctor.doctorId}.png`
                  : null
              }
              name={appointment ? appointment.DoctorName : ""}
              qualifications={`${doctorSpecialization} REG No :${doctor.RegistrationNumber ? doctor.RegistrationNumber : ""
                }`}
            />
            <Grid container mt={3}>
              <Grid item xs>
                <TitleAndContent
                  title={"Appointment No"}
                  content={appointment ? appointment.AppointmentNumber : ""}
                />
              </Grid>
              <Grid item xs>
                <TitleAndContent
                  title={"Appointment Date"}
                  content={
                    appointment
                      ? new Date(
                        appointment.SessionStartTime
                      ).toLocaleDateString()
                      : ""
                  }
                />
              </Grid>
              <Grid item xs>
                <TitleAndContent
                  title={"Appointment Time"}
                  content={
                    appointment
                      ? new Date(
                        appointment.SessionStartTime
                      ).toLocaleTimeString()
                      : ""
                  }
                />
              </Grid>
            </Grid>

            <Grid container mt={4}>
              <Grid item xs>
                <TitleAndContent
                  title={"Patient Name"}
                  content={patient.name ? patient.name : "-"}
                  contentSx={{
                    width: 150,
                  }}
                />
              </Grid>
              <Grid item xs>
                <TitleAndContent
                  title={"Mobile no"}
                  content={patient.phoneNo ? patient.phoneNo : "-"}
                />
              </Grid>
              <Grid item xs>
                <TitleAndContent
                  title={"Email"}
                  content={patient.email ? patient.email : "-"}
                />
              </Grid>
            </Grid>
          </ContentCard>
        </Grid>

        <Grid item xs>
          <ContentCard sx={{ boxShadow: 0.5, border: "1px solid #ececec" }}>
            <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
              Payment Details
            </Typography>
            <Box display={"flex"} flexDirection="column" gap={1} mt={2}>
              {channeilingFees?.DoctorFee !== 0 && (
                <TitleAndContent
                  title={"Doctor fee"}
                  content={convertToCurrency({
                    amount: channeilingFees.DoctorFee ?? 0,
                    currency: "Rs",
                  })}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                />
              )}
              {channeilingFees?.HospitalFee !== 0 && (
                <TitleAndContent
                  title={"Hospital fee"}
                  content={convertToCurrency({
                    amount: channeilingFees.HospitalFee ?? 0,
                    currency: "Rs",
                  })}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                />
              )}

              {channeilingFees?.ServiceFee !== 0 && (
                <TitleAndContent
                  title={"Service fee"}
                  content={convertToCurrency({
                    amount: channeilingFees.ServiceFee,
                    currency: "Rs",
                  })}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                />
              )}

              {channeilingFees?.OtherFee !== 0 && (
                <TitleAndContent
                  title={"Other fee"}
                  content={convertToCurrency({
                    amount: channeilingFees.OtherFee,
                    currency: "Rs",
                  })}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                />
              )}

              <Divider orientation="horizontal" />
              <FlexBox sx={{ justifyContent: "end", gap: 2 }}>
                <Typography fontWeight={"bold"} sx={{ color: "#787A91" }}>
                  Total :
                </Typography>
                <Typography fontWeight={"bold"}>
                  {convertToCurrency({
                    amount:
                      (channeilingFees.DoctorFee ?? 0) +
                      (channeilingFees.HospitalFee ?? 0) +
                      (channeilingFees.ServiceFee ?? 0) +
                      (channeilingFees.OtherFee ?? 0),

                    currency: "Rs",
                  })}
                </Typography>
              </FlexBox>
            </Box>
          </ContentCard>

          <ContentCard
            sx={{
              mt: 2,
              boxShadow: 0.5,
              border: "1px solid #ececec",
              display: "flex",
              flexDirection: "column",
              gap: 0.6,
              alignItems: "start",
            }}
          >
            <Typography fontWeight={"bold"} fontSize={"1.2rem"}>
              Payment Method
            </Typography>
            {paymentChipText && (
              <Chip label={paymentChipText} color="primary" size="small" />
            )}
            {(paymentMethod === PAYMENT_METHODS.CASH_PAYMENT ||
              paymentChipText === null) && (
                <Typography>*Please make the payment to reception</Typography>
              )}
          </ContentCard>
        </Grid>
      </Grid>
    </>
  );
};

export default StepOne;
