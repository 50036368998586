import { postAsync } from "./apiManager";

export const appointmentStatus = (
  AppointmentId,
  onSuccess,
  onFailed,
  onComplete
) => {
  postAsync(
    `ChanalingStatus`,
    {
      AppointmentId,
    },
    onSuccess,
    onFailed,
    onComplete
  );
};

export const sessionStatus = (SessionId, onSuccess, onFailed, onComplete) => {
  postAsync(
    `ChanalingStatus`,
    {
      SessionId,
    },
    onSuccess,
    onFailed,
    onComplete
  );
};
